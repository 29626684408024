.header {
    /* width: 80%; */
    padding: 20px 10%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F1F2F3;
}

.logo img {
    max-width: 200px;
}

.menu ul {
    text-decoration: none;
    display: flex;
    column-gap: 20px;
}

.menu ul li {
    text-decoration: none;
    display: block;
    cursor: pointer;
    text-transform: uppercase;

    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 1.0rem;
    padding: 0 0.75rem;
}

.menu ul li::after, .activeMenu::after{
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #886e45;
    margin-top: 0.3125rem;
    transition: width .3s;
}

.activeMenu::after {
    width: 100% !important;
}

.menu ul li:hover::after {
    width: 100%;
}

.menu ul li a {
    text-decoration: none;
    color: #575757;
}
