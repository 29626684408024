@font-face {
  font-family: 'MyCustomFont';
  src: url('./assets/Roboto-Regular.ttf') format('truetype');
}

* {
  font-family: 'Montserrat', sans-serif !important;
}

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
