.strategies {}

.header-intro-text {
    padding: 10% 10%;
    position: relative;
}

.header-intro-text h1 {
    font-family: 'Amiri', serif;
    font-weight: 400;
    font-size: 4rem;
    line-height: 5rem;
    color: #226092;
    margin-bottom: 0.625rem;
    position: relative;
    z-index: 4;
}

.management {
    padding: 0% 10%;
    display: flex;
}

.content-item {
    width: 33%;
    padding-right: 0.6875rem;
    padding-left: 0.6875rem;
}

.management .content-item h3 {
    font-family: 'Amiri', serif;
    font-weight: 400;
    font-size: 2.8125rem;
    line-height: 2.8125rem;
    color: #226092;
}

.management .content-item p {
    clear: both;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 0.9375rem;
    line-height: 1.5625rem;
    color: #575757;
    margin-top: 1.5625rem;
}

.management .content-item hr {
    width: 1.5625rem;
    border-color: #BE9A60;
    display: block;
    margin-top: 1.25rem;
    float: left;
}

.strategies-menu {
    padding: 0 10%;
    display: flex;
    align-items: center;
    height: 10em;
}

.strategies-menu div {
    width: 100%;
    height: 5.625rem;
    line-height: 5.625rem;
    padding: 0;
    text-align: center;
    transition: all 0.3s linear;
    text-transform: uppercase;
}

.strategies-menu div:hover {
    padding: 2rem 0;
}

.strategies-menu div a {
    text-decoration: none;
    color: white;
    font-size: 1.5rem;
}

.strategies-menu div:first-child {
    background: #9ab858;
}

.strategies-menu div:last-child {
    background: #1978c4;
}

.our-principles {
    padding: 0 10%;
    color: #226092;
    font-family: 'Amiri', serif;
    font-size: 1.5rem;
    line-height: 3.125rem;
}

.slick-list {
    display: flex;
    column-gap: 10px;
}

.slick-list div {
    width: 20%;
}

.slick-list h5 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1.25rem;
    color: #BE9A60;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
}

.slick-list p {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 0.9375rem;
    line-height: 1.5625rem;
    color: #575757;
}

.final-point {
    padding: 10% 10%;
    font-family: 'Amiri', serif;
    font-size: 2.5rem;
    color: #226092;
    text-align: right;
}