/* General Styling */

/* Auth Container */
.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  background-color: #f3f4f6;
  font-size: 1.0rem !important;
}

/* Auth Box */
.auth-box {
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 100%;
  max-width: 400px;
  text-align: center;
}

/* Tabs */
.auth-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.auth-tab {
  flex: 1;
  padding: 10px 0;
  font-size: 16px;
  font-weight: bold;
  border: none;
  background: #f3f4f6;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.auth-tab.active {
  background: #BFA34B;
  color: white;
}

.auth-tab:not(.active):hover {
  background: #e0e0e0;
}

/* Title */
.auth-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

/* Form Group */
.form-group {
  margin-bottom: 15px;
  text-align: left;
}

.form-group label {
  font-size: 14px;
  font-weight: 600;
  color: #555;
}

.form-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 14px;
  margin-top: 5px;
  transition: border-color 0.2s ease-in-out;
}

.form-input:focus {
  outline: none;
  border-color: #7c6b37;
}

/* Auth Button */
.auth-button {
  background-color: #BFA34B;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.auth-button:hover {
  background-color: #7c6b37;
}

/* Link Button */
.link-button {
  background: none;
  border: none;
  color: #BFA34B;
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
  margin-top: 10px;
}

.link-button:hover {
  color: #7c6b37;
}