.office {
    padding: 0 10%;
    margin-top: -5%;
    font-size: 1.0rem !important;
}

.office-details h5 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 0.9375rem;
    line-height: 1.1875rem;
    margin: 0;
    color: #BE9A60;
}

.office-details p {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    color: #575757;
    font-size: 0.9375rem;
    line-height: 1.1875rem;
}

.office-details p a {
    color: #BE9A60;
    text-decoration: none;
}

.bigLine {
    position: absolute;
    bottom: 0;
    display: block;
    top: 3.75rem;
    right: 40rem;
    width: 300px;
}

.bigLine::after {
    content: url('../../assets/logo.png');
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    /* mask-image: linear-gradient(to bottom, black, transparent); */
    /* transform: skew(-30deg); */
    /* background: linear-gradient(0deg, rgba(217, 129, 194, 0) 0%, rgba(193, 156, 98, 0.2) 100%); */
    opacity: 1;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 2s;
}