.pifocus {
    padding: 5% 10%;

    .page-title {
        font-size: 3.125rem;
        line-height: 2.8125rem;
        color: #226092;
        text-transform: uppercase;
        padding-bottom: 50px;
    }

    p {
        font-family: 'Amiri', serif;
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 1.8rem;
        color: #575757;
    }

    .block {
        .title {
            font-family: 'Amiri', serif;
            font-weight: 400;
            font-size: 1.8rem;
            line-height: 2.0625rem;
            color: #226092;
            text-transform: uppercase;
        }
    }
}